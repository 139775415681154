import React from "react";
import { Link } from "react-router-dom";
import "../css/TrackButton.css";

function TrackPackageButton({ caption }) {
  const trackAnotherButtonCaption = caption || "Track another order";

  return (
    <div
      className="sticky-top d-flex justify-content-end mt-3"
      style={{ pointerEvents: "none" }}
    >
      <Link
        to="/"
        className="btnTrackPackage text-center"
        style={{ pointerEvents: "auto" }}
      >
        {trackAnotherButtonCaption}
      </Link>
    </div>
  );
}

export default TrackPackageButton;
