import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";

import "../css/OrderHeader.css";
import { Container } from "react-bootstrap";
import { Trans } from "react-i18next";

function OrderHeader({ trackingNumber, supportPhoneNumber, supportEmail }) {
  const mailto = `mailto:${supportEmail}`;
  const tel = `tel:${supportPhoneNumber}`;

  return (
    <Container fluid className="ps-2 pe-2 pt-4 pb-4 order-header">
      <div className="d-flex d-lg-none d-lg-flex flex-column">
        <h5 className="m-0 p-0">
          <b>
            <Trans i18nKey="Generic.trackingNumberVal">
              Tracking Number: {{ trackingNumber }}
            </Trans>
          </b>
        </h5>
        <span>
          {tel !== "tel:" && (
            <span className="d-inline">
              <a href={tel} className="me-2">
                <FontAwesomeIcon icon={faPhone} className="me-2" />
                {supportPhoneNumber}
              </a>
            </span>
          )}
          <span className="d-inline">
            <a href={mailto} className="me-2">
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              {supportEmail}
            </a>
          </span>
        </span>
      </div>
      <div className="d-none d-lg-flex justify-content-between ">
        <h5 className="m-0 p-0">
          <Trans i18nKey="Generic.trackingNumberVal">
            Tracking Number: {{ trackingNumber }}
          </Trans>
        </h5>
        <span>
          {tel !== "tel:" && (
            <span className="d-inline">
              <a href={tel} className="me-2">
                <FontAwesomeIcon icon={faPhone} className="me-2" />
                {supportPhoneNumber}
              </a>
            </span>
          )}
          <span className="d-inline">
            <a href={mailto} className="me-2">
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              {supportEmail}
            </a>
          </span>
        </span>
      </div>
    </Container>
  );
}

export default OrderHeader;
