import { Trans } from "react-i18next";

function CancellationInfo({ trackingNumber, data }) {
  const { orderDetails: { lastUpdated, lastUpdatedTimeZone, cancelledBy, cancellationReason }
  } = data;
  return (
    <div className="d-flex">
      <div className="cancelledInfoContent">
        <p>
          <b>
            <Trans i18nKey="CancellationInfo.details">
              Cancellation Details
            </Trans>
          </b>
        </p>
        <hr />
        <p>
          <Trans i18nKey="CancellationInfo.order">
            Order Number: <b>{{ trackingNumber }}</b>
          </Trans>
        </p>
        <p>
          <Trans i18nKey="CancellationInfo.time">
            Time of Cancellation:
            <b>
              {{ lastUpdated }}
              {{ lastUpdatedTimeZone }}
            </b>
          </Trans>
        </p>
        <p>
          <Trans i18nKey="CancellationInfo.name">
            Name: <b>{{ cancelledBy }}</b>
          </Trans>
        </p>
        <p>
          <Trans i18nKey="CancellationInfo.reason">
            Reason: <b>{{ cancellationReason }}</b>
          </Trans>
        </p>
      </div>
    </div>
  );
}

export default CancellationInfo;
