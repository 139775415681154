import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "../css/TrackOrderFormPage.css";
import ItemNotFoundModal from "../modals/ItemNotFoundModal";
import {
  FetchMultipleOrderDatas,
  FetchOrderData,
  HttpError,
  NotFoundError,
  UnknownError,
} from "../functions/ApiCalls";
import { useTranslation, Trans } from "react-i18next";

function TrackOrderFormPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isTrackingNumberError, setTrackingInputError] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [errorDetails, setErrorDetails] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTrackingInputError(false);
    setErrorDetails (null);// clear the error details.
    setIsLoading(false);
    const formData = new FormData(e.target);
    const trackingNumber = formData.get("trackingNumber").trim();

    if (trackingNumber === "") {
      setTrackingInputError(true);
      return;
    }

    setIsLoading(true);

    const multipleResults = await FetchMultipleOrderDatas(trackingNumber);

    if (
      !(multipleResults instanceof HttpError) &&
      !(multipleResults instanceof UnknownError) &&
      multipleResults.length > 1
    ) {
      navigate(`/trackMultiple/${trackingNumber}`);
      return;
    }

    const result = await FetchOrderData(trackingNumber);

    if (result instanceof HttpError) {
      setErrorDetails (result.errorDetails);
      handleOpenErrorModal();

      setIsLoading(false);
      return;
    }

    if (result instanceof NotFoundError) {
      setErrorDetails (result.errorDetails);
      handleOpenErrorModal();

      setIsLoading(false);
      return;
    }

    if (result instanceof UnknownError) {
      setErrorDetails (result.errorDetails);
      handleOpenErrorModal();

      setIsLoading(false);
      return;
    }

    if (result.orderDetails) {
      navigate(`/orderNumber/${result.orderDetails.appleTrackingNumber}`);
      document.documentElement.scrollIntoView({ behavior: "smooth" });
    } else {
      handleOpenErrorModal();

      setIsLoading(false);
    }
  };

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleOpenErrorModal = () => {
    setIsErrorModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <>
      <div className="container d-flex justify-content-center mb-5">
        <div className="form-phone d-inline-flex justify-content-evenly align-items-center mt-5">
          <a href="tel:18886412142">P: 1.888.942.7753 (Canada)</a>
          <a href="tel:18889427753">P: 1.888.672.7753 (USA)</a>
        </div>
      </div>

      <div className="container justify-content-center d-flex">
        <form onSubmit={handleSubmit}>
          <fieldset
            disabled={isLoading}
            className="d-flex justify-content-center flex-column"
          >
            <div className="row text-center mb-5">
              <h1>{t("TrackOrderFormPage.title")}</h1>
              <p>{t("TrackOrderFormPage.prompt")}</p>
              <p>
                <Trans i18nKey="TrackOrderFormPage.trackMultiple">
                  Click <Link to="/multipleOrders">here</Link> to track multiple
                  orders.
                </Trans>
              </p>
            </div>
            <div className="mb-3">
              <label htmlFor="trackingNumber" className="form-label">
                {t("TrackOrderFormPage.trackingNumber")}
              </label>
              <input
                type="trackingNumber"
                className={
                  isTrackingNumberError
                    ? "form-control is-invalid"
                    : "form-control"
                }
                id="trackingNumber"
                name="trackingNumber"
                aria-describedby="trackingNumber"
                placeholder={t("TrackOrderFormPage.trackingNumberError")}
                required={true}
                autoFocus={true}
              />
              <div className="invalid-feedback">
                {t("TrackOrderFormPage.trackingNumberError")}
              </div>
            </div>

            <div>
              <button
                type="reset"
                className="btn btn-link p-0 m-0 text-secondary"
              >
                {t("TrackOrderFormPage.reset")}
              </button>
            </div>

            <button type="submit" className="btn btn-primary flex-grow-1">
              {isLoading ? "..." : t("TrackOrderFormPage.submit")}
            </button>
            <div className="d-flex justify-content-between">
              <a
                className="text-secondary"
                target="_blank"
                href="https://goapple.appleexpress.com/"
                rel="noreferrer"
              >
                {t("TrackOrderFormPage.clientLogin")}
              </a>
              <a
                className="text-secondary"
                target="_blank"
                href="http://www.appleexpress.com/vendorportal"
                rel="noreferrer"
              >
                {t("TrackOrderFormPage.agentLogin")}
              </a>
            </div>
          </fieldset>
        </form>
      </div>
      <ItemNotFoundModal isOpen={isErrorModalOpen} onClose={handleCloseModal} errorDetails={errorDetails} />
    </>
  );
}

export default TrackOrderFormPage;
