import React, { useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Row, Col } from "react-bootstrap";

function AEXProgressBar({ data }) {
  const [percent, setPercent] = React.useState(0);

  useEffect(() => {
    setTimeout(() => {
      setPercent(data.screenDetails.progressPercentage);
    }, 100);
  }, [data.screenDetails.progressPercentage, percent]);

  return (
    <Row className="ms-0 me-0">
      <Col xs={12}>
        <ProgressBar now={percent} animated variant="success" />
      </Col>
    </Row>
  );
}

export default AEXProgressBar;
