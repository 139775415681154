import { Row } from "react-bootstrap";
import { Trans } from "react-i18next";

function PUDOLocationDetails({ data }) {
  const { screenDetails: { pudoLocation: { name, address, operatingHours, phone } } } = data;
  return (
    <Row className="mb-3 ms-0 me-0">
      <h2>
        <Trans i18nKey="PUDOLocationDetails.title">
          PUDO Location Details:
        </Trans>
      </h2>
      <p>
        <Trans i18nKey="PUDOLocationDetails.name">
          <b>Name of Location: </b>{{ name }}
        </Trans>
      </p>
      <p>
        <Trans i18nKey="PUDOLocationDetails.address">
          <b>Address: </b>
          <a
            href={`https://www.google.com/search?q=${encodeURIComponent(
              data.screenDetails.pudoLocation.address
            )}`}
            style={{ color: "white" }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ address }}
          </a>
        </Trans>
      </p>
      <p>
        <Trans i18nKey="PUDOLocationDetails.operatingHours">
          <b>Operating Hours:</b> {{ operatingHours }}
        </Trans>
      </p>
      <p>
        <Trans i18nKey="">
          <b>Phone Number:</b>
          <a
            style={{ color: "white" }}
            href={`tel:${data.screenDetails.pudoLocation.phone}`}
          >
            {{ phone }}
          </a>
        </Trans>
      </p>
    </Row>
  );
}

export default PUDOLocationDetails;
