import React from "react";
import { getIcon } from "../functions/iconUtils";
import { Row, Col } from "react-bootstrap";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

function LatestUpdates({ data }) {
  const { t } = useTranslation();

  return (
    <Row className="ms-0 me-0 mt-3">
      <Col xs={12}>
        <p>
          <b>
            <Trans i18nKey="LatestUpdate.title">
              Latest Updates
            </Trans>
          </b>
        </p>
        <table className="table table-striped mb-0 table-sm">
          <thead>
            <tr>
              <th className="col-xs-2"></th>
              <th className="col-xs-2">
                <Trans i18nKey="Generic.date">
                  Date
                </Trans>
              </th>
              <th className="col-xs-1">
                <Trans i18nKey="Generic.time">
                  Time
                </Trans>
              </th>
              <th className="col-xs-3">
                <Trans i18nKey="LatestUpdate.progress">
                  Progress
                </Trans>
              </th>
              <th className="col-xs-3">
                <Trans i18nKey="LatestUpdate.location">
                  Location
                </Trans>
              </th>
            </tr>
          </thead>
          <tbody>
            {/* Logic to allow user to click status and track the flight for NFO orders only using Sirium website*/}
            {/* You can add the Sirium API here when ready */}
            {data?.statusDetails?.map((statusDetail, index) => {
              const flightNumberRegex = /Flight ([A-Z0-9]+) (\d+)/;
              const match = statusDetail.status.match(flightNumberRegex);
              const date = new Date(statusDetail.generatedAt);
              const year = date.getFullYear();
              const month = (date.getMonth() + 1).toString().padStart(2, "0");
              const day = date.getDate().toString().padStart(2, "0");
              const { generatedAtTimeZone, generatedAt } = statusDetail;
              return (
                <tr key={index}>
                  <td className="col-xs-2">
                    {getIcon(statusDetail.statusIconId)}
                  </td>
                  <td className="col-xs-2">
                    <Trans i18nKey="LatestUpdate.date">
                      {{ generatedAt }}
                    </Trans>
                  </td>
                  <td className="col-xs-1">
                    <Trans i18nKey="LatestUpdate.time">
                      {{ generatedAt }} {{ generatedAtTimeZone }}
                    </Trans>
                  </td>
                  <td className="col-xs-3">
                    {match ? (
                      // Show clickable flight status for NFO orders
                      <a
                        href={`https://www.flightstats.com/v2/flight-tracker/${match[1]}/${match[2]}?year=${year}&month=${month}&date=${day}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "red" }}
                        title={t("LatestUpdate.trackFlight")}
                      >
                        {statusDetail.status}
                      </a>
                    ) : (
                      statusDetail.status
                    )}
                  </td>
                  <td className="col-xs-3">{statusDetail.location}</td>
                </tr>
              );
            }) || (
                <tr>
                  <td colSpan="5" className="col-xs-12">
                    <Trans i18nKey="LatestUpdate.noUpdates">
                      No updates available
                    </Trans>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}

export default LatestUpdates;
