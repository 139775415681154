import * as React from "react";
import { Outlet } from "react-router-dom";
import Theme from "./components/Theme";
import Header from "./components/Header";
import { Container } from "react-bootstrap";

export default function Layout() {
  return (
    <>
      <Container fluid className="p-0">
        <Header />
        <Outlet />
        <Theme />
      </Container>
    </>
  );
}
