import React, { useState } from "react";
import { Row } from "react-bootstrap";
import "../css/Info.css";
import { Trans } from "react-i18next";
import { useTranslation } from "react-i18next";

function FAQ({ isECommerce }) {
  const [activeIndex, setActiveIndex] = useState(-1);
  const { t } = useTranslation();

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };

  // FAQ Static Information Data //
  const ecommerceFaqData = [
    {
      question: "FAQ.ecommerce.asnStatus",
      answer: "FAQ.ecommerce.asnStatusAnswer",
    },
    {
      question: "FAQ.ecommerce.notAtHome",
      answer: "FAQ.ecommerce.notAtHomeAnswer",
    },
    {
      question: "FAQ.ecommerce.deliverOnWeekend",
      answer: "FAQ.ecommerce.deliverOnWeekendAnswer",
    },
    {
      question: "FAQ.ecommerce.deliveryHours",
      answer: "FAQ.ecommerce.deliveryHoursAnswer",
    },
    {
      question: "FAQ.ecommerce.missedDelivery",
      answer: "FAQ.ecommerce.missedDeliveryAnswer",
    },
    {
      question: "FAQ.ecommerce.damagedPackage",
      answer: "FAQ.ecommerce.damagedPackageAnswer",
    },
  ];

  const onDemandFaqData = [
    {
      question: "FAQ.onDemand.howToTrack",
      answer: "FAQ.onDemand.howToTrackAnswer",
    },
    {
      question: "FAQ.onDemand.whenCanICancel",
      answer: "FAQ.onDemand.whenCanICancelAnswer",
    },
  ];

  let faqData = onDemandFaqData;
  if (isECommerce) {
    faqData = ecommerceFaqData;
  }

  return (
    <Row className="mb-3 ms-0 me-0">
      <h2>
        <Trans i18nKey="FAQ.title">Support</Trans>
      </h2>
      {faqData.map((faq, index) => (
        <div
          key={index}
          className={`question ${index === activeIndex ? "active" : ""}`}
          onClick={() => toggleAccordion(index)}
        >
          <div id="info-dropdown" className="question-header">
            <span>{t(faq.question)}</span>
            <i className={`arrow ${index === activeIndex ? "active" : ""}`}></i>
          </div>
          <div className={`answer ${index === activeIndex ? "active" : ""}`}>
            <p>{t(faq.answer)}</p>
          </div>
        </div>
      ))}
      {isECommerce && (
        <p>
          <Trans i18nKey="FAQ.forMore">
            For more frequently asked questions, please click
            <a
              target="_blank"
              href="https://www.appleexpress.com/zone-parcel-delivery-faq/"
              style={{ color: "#f20036" }}
              rel="noreferrer"
            >
              here
            </a>
          </Trans>
        </p>
      )}
    </Row>
  );
}

export default FAQ;
