import React from "react";
import logo from "../assets/logo.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "../css/Header.css";

import { useTranslation } from "react-i18next";
import { LanguageSwitcherButton } from "./LanguageSwitcherButton";

function Header({ displayNav }) {
  const { t } = useTranslation();
  return (
    <Navbar expand="lg">
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            className="logo"
            src={logo}
            alt="AppleExpress"
            width="300px"
          ></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <LanguageSwitcherButton />
            <Nav.Link href="https://appleexpress.com/about-us">
              {t("Header.about")}
            </Nav.Link>
            <Nav.Link href="https://appleexpress.com/transportation">
              {t("Header.transportation")}
            </Nav.Link>
            <Nav.Link href="https://appleexpress.com/logistics">
              {t("Header.logistics")}
            </Nav.Link>
            <Nav.Link href="https://appleexpress.com/industries">
              {t("Header.industries")}
            </Nav.Link>
            <Nav.Link href="https://appleexpress.com/infrastructure">
              {t("Header.infrastructure")}
            </Nav.Link>
            <Nav.Link href="https://appleexpress.com/media">
              {t("Header.media")}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
