import React from "react";

import { getIcon } from "../functions/iconUtils";
import "../css/Title.css";
import { Row, Col } from "react-bootstrap";
function Title({ data }) {
  const icon = getIcon(data?.orderDetails?.statusIconId);

  return (
    <Row className="ms-0 me-0 d-flex">
      <Col xs="auto">
        <span className="align-self-center">{icon}</span>
      </Col>
      <Col>
        <h1>{data.orderDetails.currentStatus}</h1>
        <p className="text-secondary">{data.orderDetails.statusChangeText}</p>
        <p>{data.screenDetails.statusDescription}</p>
      </Col>
    </Row>
  );
}

export default Title;
