import React from "react";
import TrackPackageButton from "../components/TrackButton";
import { useRouteError } from "react-router-dom";
import { Trans } from "react-i18next";

function ErrorPage() {
  const error = useRouteError();
  const { message } = error;
  return (
    <>
      <TrackPackageButton />

      <div className="container justify-content-center d-flex flex-column text-center">
        <h1 className="text-danger display-1">400</h1>
        <Trans i18nkey="Error.message">
          <p>There was an error with your request: <b>{{ message }}</b></p>
        </Trans>
      </div>
    </>
  );
}

export default ErrorPage;
