import React from "react";
import TrackPackageButton from "../components/TrackButton";
import { Trans } from "react-i18next";

function NotFoundPage() {
  return (
    <>
      <TrackPackageButton />

      <div className="container justify-content-center d-flex flex-column text-center">
        <h1 className="text-danger display-1">404</h1>
        <Trans i18nKey={"NotFound.message"}>
          <p>The page you are looking for does not exist. aaa</p>
        </Trans>
      </div>
    </>
  );
}

export default NotFoundPage;
