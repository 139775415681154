import React from "react";
import Title from "../components/Title";
import OrderDetails from "../components/OrderDetails";
import AEXProgressBar from "../components/AEXProgressBar";
import LatestUpdates from "../components/LatestUpdates";
import DeliveryActionButtons from "../components/DeliveryActionButtons";
import TrackPackageButton from "../components/TrackButton";
import FAQ from "../components/FAQ";
import OrderHeader from "../components/OrderHeader";
import { useLoaderData } from "react-router-dom";
import { mapOrderStatusId } from "../constants";
import CancellationInfo from "../components/CancellationInfo";
import PUDOLocationDetails from "../components/PUDOLocationDetails";
import ECommerceDeliveryOptions from "../components/ECommerceDeliveryOptions";
import { Col, Container, Row } from "react-bootstrap";
import { Trans, useTranslation  } from "react-i18next";

function OrderDetailsPage() {
  useTranslation();// Required (from the useTranslation import, via react-i18next) to make the translations work from within this component.
  const { order, trackingNumber } = useLoaderData();
  const isOrderCancelled =
    mapOrderStatusId(order.orderDetails.statusId) === "Cancelled";
  const isPUDOOrder = order.orderDetails.statusId === 24;
  const isECommerce = order.orderDetails.isECommerce;

  let contactAction = "https://www.appleexpress.com/contact";
  if (order.screenDetails.supportEmailAction === 1) {
    contactAction = `mailto:${order.screenDetails.supportEmail}`;
  }

  return (
    <>
      <OrderHeader
        trackingNumber={trackingNumber}
        supportEmail={order.screenDetails.supportEmail}
        supportPhoneNumber={order.screenDetails.supportPhoneNumber}
      />
      <TrackPackageButton trackingNumber={trackingNumber} data={order} />

      <Container fluid>
        <Row className="ms-0 me-0">
          <Col xs={12} lg={8}>
            <Title data={order} />
            <DeliveryActionButtons data={order} />
            {isOrderCancelled && (
              <CancellationInfo trackingNumber={trackingNumber} data={order} />
            )}
            {!isOrderCancelled && <OrderDetails data={order}/>}
            {!isOrderCancelled && <AEXProgressBar data={order} />}
            <LatestUpdates data={order} />
            <p className="mt-4">
              <Trans i18nKey="OrderDetails.contactUs">
                For more information please&nbsp;
                <a target="_blank" href={contactAction} rel="noreferrer">
                  contact us.
                </a>
              </Trans>
            </p>
          </Col>
          <Col xs={12} lg={4}>
            {isPUDOOrder && <PUDOLocationDetails data={order} />}
            {isECommerce && <ECommerceDeliveryOptions data={order} />}
            <FAQ isECommerce={isECommerce} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default OrderDetailsPage;
