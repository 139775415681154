import React, { useState, useCallback } from "react";
import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";
import proofIcon from "../assets/icons/Green/APX_Delivered._80x80.png";

import JSZip from "jszip";
import { saveAs } from "file-saver";
import {
  FetchPODDetails,
  HttpError,
  NotFoundError,
  UnknownError,
  VerifyPOD,
} from "../functions/ApiCalls";
import { useNavigate, useParams } from "react-router";
import { useTranslation, Trans } from "react-i18next";

const PODOnDemandModal = () => {
  const { trackingNumber } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation()

  const [currentPage, setCurrentPage] = useState(1);
  const [verificationError, setVerificationError] = useState("");
  const [receivedBy, setReceivedBy] = useState("");
  const [orderImages, setOrderImages] = useState([]); // State to hold the order images
  const [isLoading, setIsLoading] = useState(false);

  const downloadImagesAsZip = useCallback(() => {
    const zip = new JSZip();

    orderImages.forEach((imageData, index) => {
      // Convert base64 to a blob
      const byteCharacters = atob(imageData);
      const byteArrays = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays[i] = byteCharacters.charCodeAt(i);
      }

      // Add the blob to the zip
      zip.file(`image_${index + 1}.png`, byteArrays, { binary: true });
    });

    // Generate the zip file
    zip.generateAsync({ type: "blob" }).then((blob) => {
      // Save the zip file using file-saver
      saveAs(blob, "images.zip");
    });
  }, [orderImages]);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();

      const formData = new FormData(event.target);
      const accountNumber = formData.get("accountNumber").trim();
      const destinationPostalCode = formData
        .get("destinationPostalCode")
        .trim();

      if (accountNumber === "" && destinationPostalCode === "") {
        setVerificationError(t("PODOnDemandModal.verifyErrors.emptyValues"));
        return;
      }

      setIsLoading(true);

      const result = await VerifyPOD(
        trackingNumber,
        accountNumber,
        destinationPostalCode
      );

      if (result instanceof HttpError) {
        setVerificationError(t("PODOnDemandModal.verifyErrors.httpError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof NotFoundError) {
        setVerificationError(t("PODOnDemandModal.verifyErrors.notFoundError"));
        setIsLoading(false);

        return;
      }

      if (result instanceof UnknownError) {
        setVerificationError(t("PODOnDemandModal.verifyErrors.unknownError"));
        setIsLoading(false);
        return;
      }

      const podDataResult = await FetchPODDetails(
        trackingNumber,
        accountNumber,
        destinationPostalCode
      );

      if (podDataResult instanceof HttpError) {
        setVerificationError(t("PODOnDemandModal.fetchErrors.httpError", { status: podDataResult.status }));
        setIsLoading(false);
        return;
      }

      if (podDataResult instanceof NotFoundError) {
        setVerificationError(t("PODOnDemandModal.fetchErrors.notFoundError"));
        setIsLoading(false);
        return;
      }

      if (podDataResult instanceof UnknownError) {
        setVerificationError(t("PODOnDemandModal.fetchErrors.unknownError"));
        setIsLoading(false);
        return;
      }

      setVerificationError(""); // Reset the verification error on success
      setIsLoading(false);
      setReceivedBy(podDataResult.receivedBy);
      setOrderImages(podDataResult.orderImage);
      setCurrentPage(currentPage + 1);
    },
    [currentPage, t, trackingNumber]
  );

  const handleOnHide = () => {
    navigate("..", { relative: "path" });
  };

  return (
    <Modal
      show={true}
      onHide={handleOnHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src={proofIcon} alt="sms" className="me-2" height="40px"></img>
          <Trans i18nKey="PODOnDemandModal.title">
            Proof of Delivery & Signature
          </Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPage === 1 && (
          <form onSubmit={verifyPage1}>
            <fieldset
              disabled={isLoading}
              className="d-flex justify-content-center flex-column"
            >
              <p>
                <Trans i18nKey="PODOnDemandModal.prompt">
                  Enter your account number{" "}
                  <b>
                    <u>or</u>
                  </b>{" "}
                  postal code so we can verify you.
                </Trans>
              </p>
              <div className="mb-3">
                <label htmlFor="accountNumber" className="form-label">
                  <Trans i18nKey="Generic.accountNumber">
                    Account Number
                  </Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNumber"
                  name="accountNumber"
                  aria-describedby="accountNumber"
                  placeholder="200211283"
                  autoFocus={true}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="destinationPostalCode" className="form-label">
                  <Trans i18nKey="Generic.destinationPostalCode">
                    Destination Postal Code
                  </Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="destinationPostalCode"
                  name="destinationPostalCode"
                  aria-describedby="destinationPostalCode"
                  placeholder="L4W5J2"
                  maxLength="7"
                />
              </div>

              {verificationError && (
                <b>
                  <p className="text-danger text-center">{verificationError}</p>
                </b>
              )}

              <button className="btn btn-primary" type="submit">
                {isLoading ? "..." : t("Generic.continue")}
              </button>
            </fieldset>
          </form>
        )}

        {currentPage === 2 && (
          <div className="d-flex justify-content-center flex-column">
            <Trans i18nKey="PODOnDemandModal.title">
              Proof of Delivery & Signature
            </Trans>
            {orderImages.length > 0 ? (
              <>
                <Carousel
                  controls={orderImages.length > 1}
                  indicators={orderImages.length > 1}
                >
                  {orderImages.map((imageData, index) => (
                    <Carousel.Item key={index} style={{ minHeight: "300px" }}>
                      <img
                        style={{
                          objectFit: "scale-down",
                        }}
                        className="d-block w-100"
                        src={`data:image/png;base64,${imageData}`}
                        alt={`${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
                <button
                  className="btn btn-link mt-3"
                  onClick={downloadImagesAsZip}
                >
                  <Trans i18nKey="PODOnDemandModal.downloadImages">
                    Download Images
                  </Trans>
                </button>
              </>
            ) : (
              <p>
                <Trans i18nKey="PODOnDemandModal.noImages">
                  There is no POD image for this order.
                </Trans>
              </p>
            )}
            <br />
            <p className="text-center">
              <Trans i18nKey="PODOnDemandModal.receivedBy">
                Received by: <b>{{ receivedBy }}</b>
              </Trans>
            </p>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default PODOnDemandModal;
