import React, { useState, useCallback } from "react";
import "react-datepicker/dist/react-datepicker.css";
import smsIcon from "../assets/icons/New/SMS.png";

import Modal from "react-bootstrap/Modal";
import {
  HttpError,
  NotFoundError,
  UnknownError,
  VerifySMS,
  SMSSignup,
} from "../functions/ApiCalls";
import { useNavigate, useParams } from "react-router";
import { useTranslation, Trans } from "react-i18next";

const SMSModal = () => {
  const { trackingNumber } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [accountNumber, setAccountNumber] = useState();
  const [deliveryPostalCode, setDeliveryPostalCode] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [verificationError, setVerificationError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPhoneNumberError, setIsPhoneNumberError] = useState(false);

  const verifyPage1 = useCallback(
    async (event) => {
      event.preventDefault();

      setIsLoading(false);

      const formData = new FormData(event.target);
      const accountNumber = formData.get("accountNumber").trim();
      const deliveryPostalCode = formData.get("destinationPostalCode").trim();

      if (accountNumber === "" && deliveryPostalCode === "") {
        setVerificationError(t("SMSModal.verifyErrors.emptyValues"));
        return;
      }

      setIsLoading(true);

      const results = await VerifySMS(
        trackingNumber,
        accountNumber,
        deliveryPostalCode
      );

      if (results instanceof HttpError) {
        setVerificationError(t("SMSModal.verifyErrors.httpError"));
        setIsLoading(false);
        return;
      }

      if (results instanceof UnknownError) {
        setVerificationError(t("SMSModal.verifyErrors.unknownError"));
        setIsLoading(false);
        return;
      }

      if (results instanceof NotFoundError) {
        setVerificationError(t("SMSModal.verifyErrors.notFoundError"));
        setIsLoading(false);
        return;
      }

      setVerificationError("");
      setCurrentPage(currentPage + 1);
      setAccountNumber(accountNumber);
      setDeliveryPostalCode(deliveryPostalCode);
      setIsLoading(false);
    },
    [currentPage, t, trackingNumber]
  );

  const verifyPage2 = useCallback(
    async (event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const formPhoneNumber = formData.get("phoneNumber").trim();

      if (formPhoneNumber === "") {
        setIsPhoneNumberError(true);
        return;
      }

      setIsLoading(true);
      setVerificationError("");

      const result = await SMSSignup(
        trackingNumber,
        accountNumber,
        deliveryPostalCode,
        formPhoneNumber
      );

      if (result instanceof HttpError) {
        setVerificationError(t("SMSModal.signupErrors.httpError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof NotFoundError) {
        setVerificationError(t("SMSModal.signupErrors.notFoundError"));
        setIsLoading(false);
        return;
      }

      if (result instanceof UnknownError) {
        setVerificationError(t("SMSModal.signupErrors.unknownError"));
        setIsLoading(false);
        return;
      }

      setCurrentPage(currentPage + 1);
    },
    [accountNumber, currentPage, deliveryPostalCode, t, trackingNumber]
  );

  const handleOnHide = () => {
    navigate("..", { relative: "path" });
  };

  return (
    <Modal
      show={true}
      onHide={handleOnHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <img src={smsIcon} alt="sms" className="me-2" height="40px"></img>
          <Trans i18nKey="SMSModal.title">
            Sign up for SMS
          </Trans>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPage === 1 && (
          <form onSubmit={verifyPage1}>
            <fieldset
              disabled={isLoading}
              className="d-flex justify-content-center flex-column"
            >
              <p>
                <Trans i18nKey="SMSModal.prompt">
                  Please enter your account number&nbsp;
                  <b>
                    <u>and</u>
                  </b>&nbsp;
                  postal code so we can verify you.
                </Trans>
              </p>

              <div className="mb-3">
                <label htmlFor="accountNumber" className="form-label">
                  <Trans i18nKey="Generic.accountNumber">
                    Account Number
                  </Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNumber"
                  name="accountNumber"
                  aria-describedby="accountNumber"
                  placeholder="200211283"
                  autoFocus={true}
                  required={true}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="destinationPostalCode" className="form-label">
                  <Trans i18nKey="Generic.destinationPostalCode">
                    Destination Postal Code
                  </Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="destinationPostalCode"
                  name="destinationPostalCode"
                  aria-describedby="destinationPostalCode"
                  placeholder="L4W5J2"
                  maxLength="7"
                  required={true}
                />
              </div>

              {verificationError && (
                <b>
                  <p className="text-danger text-center">{verificationError}</p>
                </b>
              )}
              <button className="btn btn-primary" type="submit">
                {isLoading ? "..." : t("Generic.continue")}
              </button>
            </fieldset>
          </form>
        )}
        {currentPage === 2 && (
          <form onSubmit={verifyPage2}>
            <fieldset
              disabled={isLoading}
              className="d-flex justify-content-center flex-column"
            >
              <p>
                <Trans i18nKey="SMSModal.prompt2">
                  Please enter your phone number.
                </Trans>
              </p>
              <p>
                <Trans i18nKey="SMSModal.prompt3">
                  We will send you a one-time password (OTP) to verify your phone
                  number.
                </Trans>
              </p>
              <div className="mb-3">
                <label htmlFor="phoneNumber" className="form-label">
                  <Trans i18nKey="Generic.phoneNumber">
                    Phone Number
                  </Trans>
                </label>
                <input
                  type="tel"
                  className={
                    isPhoneNumberError
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  id="phoneNumber"
                  name="phoneNumber"
                  aria-describedby="phoneNumber"
                  autoFocus={true}
                  required={true}
                  placeholder="123-456-7890"
                />{" "}
                <div className="invalid-feedback">
                  <Trans i18nKey="SMSModal.phoneNumberError">
                    Please enter a valid phone number
                  </Trans>
                </div>
              </div>

              {verificationError && (
                <b>
                  <p className="text-danger text-center">{verificationError}</p>
                </b>
              )}
              <button className="btn btn-primary" type="submit">
                {isLoading ? "..." : t("Generic.continue")}
              </button>
            </fieldset>
          </form>
        )}
        {currentPage === 3 && (
          <div className="d-flex justify-content-center flex-column">
            <h2>
              <Trans i18nKey="SMSModal.success">
                Success!
              </Trans>
            </h2>
            <p>
              <Trans i18nKey="SMSModal.success2">
                Phone is now set up to receive SMS notifications for tracking
                number: {{ trackingNumber }}
              </Trans>
            </p>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleOnHide}
            >
              <Trans i18nKey="Generic.close">
                Close
              </Trans>
            </button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default SMSModal;
