// Function to match the Order Status with the Icon //

export const getIcon = (statusIconId) => {
    // Order Created //
    if (statusIconId === 1) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Order Placed_80x80.png')} alt="Order Created" />;
    }
    // In Transit //
    if (statusIconId === 2) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Transit Canada_80x80.png')} alt="In Transit to Canada Sort Facility" />;
    }
    // Arrived //
    if (statusIconId === 3) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Arrive Canada_80x80.png')} alt="Arrived at Canada Sort Facility" />;
    }
    // Out for Delivery //
    if (statusIconId === 4) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Out for Delivery_80x80.png')} alt="Out for Delivery" />;
    }
    // Delivered //
    if (statusIconId === 5) {
        return <img className="iconImg" src={require('../assets/icons/Green/APX_Delivered._80x80.png')} alt="Delivered" />;
    }
    // Delivery Exception //
    if (statusIconId === 6 || statusIconId === 7 || statusIconId === 10) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Return to Shipper_80x80.png')} alt="Return to Shipper" />;
    }
    // Available at Pickup //
    if (statusIconId === 9) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Arrive Pickup_80x80.png')} alt="Arrive Pickup" />;
    }
    // Picked Up //
    if (statusIconId === 11) {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Driver Pickup_80x80.png')} alt="Arrive Pickup" />;
    }
    // In-Air //
    if (statusIconId === 12) {
        return <img className="iconImg" src={require('../assets/icons/New/AIR_R.png')} alt="Air" />;
    }
    // Fallback Order Placed Icon //
    else {
        return <img className="iconImg" src={require('../assets/icons/Red/APX_Order Placed_80x80.png')} alt="Order Placed" />;
    }
};
