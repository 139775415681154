// API calls.
// Note, this really should be replaced by something like [TanStackQuery](https://tanstack.com/query/v3)

export class HttpError {
  constructor(response, errorDetails) {
    this.response = response;
    this.errorDetails = errorDetails;
  }
}

export class NotFoundError {
  constructor(identifier, errorDetails) {
    this.identifier = identifier;
    this.errorDetails = errorDetails;
  }
}

export class UnknownError {
  constructor(error, errorDetails) {
    this.error = error;
    this.errorDetails = errorDetails;
  }
}


function isValidJSONResponse (response)// : Boolean
{
  return response.ok && response.headers.get('Content-Type').toLowerCase().startsWith('application/json');
}

function isValidTrackingNumber(response) {
  return !!response?.orderDetails;
}

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const FetchOrderData = async (orderNumber) => {
  const apiUrl = `${baseUrl}/order/${orderNumber}/language/E`;

  try {
    const response = await fetch(apiUrl, {
      method: "GET",
    });

    if (!isValidJSONResponse (response)) {//if (!response.ok) {
      const errorDetails = response.statusText + ': ' + await response.text();
      console.log('Error Details (single): ' + errorDetails);
      return new HttpError(response.status, errorDetails);
    }

    const data = await response.json();
    
    //response.statusText;
    
    if (!isValidTrackingNumber(data)) {
      return new NotFoundError(orderNumber);
    }
    return data;
  } catch (error) {
    console.log(error);
    return new UnknownError(error, error.message);
  }
};

export const FetchMultipleOrderDatas = async (orderNumbers) => {
  const apiUrl = `${baseUrl}/trackmultiple/${orderNumbers}/language/E`;
  var response;
  try {
    response = await fetch(apiUrl, {
      method: "GET",
    });

    if (!isValidJSONResponse(response)) {//if (!response.ok) {
      const errorDetails = response.statusText + ': ' + await response.text();
      console.log('Error Details (multi): ' + errorDetails);
      return new HttpError(response.status, errorDetails);
    }

    const data = await response.json();
    if (data.length === 0) {
      return new NotFoundError(orderNumbers);
    }
    return data;
  } catch (error) {
    console.log(error);
    return new UnknownError(error, error.message);
  }
};

export const VerifyTrackDriver = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const apiUrl = `${baseUrl}/verify/trackdriver`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }
    return new NotFoundError(orderNumber);
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};

export const TrackDriver = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const apiUrl = `${baseUrl}/trackdriver`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();
    if (data) {
      return data;
    }
    return new NotFoundError(orderNumber);
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};

export const VerifyPOD = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const apiUrl = `${baseUrl}/verify/pod`;
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }

    return new NotFoundError(orderNumber);
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};

export const FetchPODDetails = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const apiUrl = `${baseUrl}/poddetails`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data) {
      return data;
    }

    return new NotFoundError(orderNumber);
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};

export const VerifyCancelOrder = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const response = await fetch(`${baseUrl}/verify/cancelorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: Number(orderNumber),
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }

    return new NotFoundError(orderNumber);
  } catch (error) {
    return new UnknownError(error);
  }
};

export const CancelOrder = async (
  trackingNumber,
  accountNumber,
  deliveryPostalCode,
  name,
  reason
) => {
  try {
    const response = await fetch(`${baseUrl}/cancelorder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: trackingNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
        name,
        reason,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }

    return new NotFoundError(trackingNumber);
  } catch (error) {
    return new UnknownError(error);
  }
};

export const VerifyDeliveryInstructions = async (
  orderNumber,
  deliveryPostalCode
) => {
  try {
    const response = await fetch(`${baseUrl}/verify/deliveryinstructions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }

    return new NotFoundError(orderNumber);
  } catch (error) {
    return new UnknownError(error);
  }
};

export const SendDeliveryInstructions = async (
  orderNumber,
  deliveryPostalCode,
  instructions
) => {
  try {
    const response = await fetch(`${baseUrl}/delivery/instructions`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        deliveryPostalCode,
        instructions,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    return true;
  } catch (error) {
    return new UnknownError(error);
  }
};

export const VerifyDeliveryTime = async (orderNumber, deliveryPostalCode) => {
  try {
    const response = await fetch(`${baseUrl}/verify/deliverytime`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }

    return new NotFoundError(orderNumber);
  } catch (error) {
    return new UnknownError(error);
  }
};

export const SendDeliveryTime = async (
  orderNumber,
  deliveryPostalCode,
  selectedTimeSlot
) => {
  try {
    const response = await fetch(`${baseUrl}/delivery/time`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        deliveryPostalCode,
        timeInterval: selectedTimeSlot,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    return true;
  } catch (error) {
    return new UnknownError(error);
  }
};

export const VerifyGetDriverLocation = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const response = await fetch(`${baseUrl}/verify/trackdriver`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }

    return new NotFoundError(orderNumber);
  } catch (error) {
    return new UnknownError(error);
  }
};

export const GetGoogleRouteData = async (
  sourceLat,
  sourceLng,
  destinationLat,
  destinationLng
) => {
  try {
    const apiUrl = `https://routes.googleapis.com/directions/v2:computeRoutes?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "X-Goog-Api-Key": process.env.REACT_APP_GOOGLE_ROUTE_API_KEY,
        "X-Goog-FieldMask":
          "routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline",
      },
      body: JSON.stringify({
        origin: {
          location: {
            latLng: {
              latitude: sourceLat,
              longitude: sourceLng,
            },
          },
        },
        destination: {
          location: {
            latLng: {
              latitude: destinationLat,
              longitude: destinationLng,
            },
          },
        },
        travelMode: "DRIVE",
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};

export const VerifySMS = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode
) => {
  try {
    const apiUrl = `${baseUrl}/verify/smssignup`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        accountNumber: Number(accountNumber),
        deliveryPostalCode,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data === true) {
      return true;
    }
    return new NotFoundError(orderNumber);
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};

export const SMSSignup = async (
  orderNumber,
  accountNumber,
  deliveryPostalCode,
  phoneNumber
) => {
  try {
    const response = await fetch(`${baseUrl}/smssignup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderNo: orderNumber,
        deliveryPostalCode,
        accountNumber,
        phoneNumber,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    return true;
  } catch (error) {
    return new UnknownError(error);
  }
};

export const FetchPODDetailsByReference = async (accountNumber, reference) => {
  try {
    const apiUrl = `${baseUrl}/poddetailsbyreference
`;

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        accountNumber: Number(accountNumber),
        reference,
      }),
    });

    if (!response.ok) {
      return new HttpError(response.status);
    }

    const data = await response.json();

    if (data) {
      return data;
    }

    return new NotFoundError(reference);
  } catch (error) {
    console.log(error);
    return new UnknownError(error);
  }
};
